// 
// authentication.scss
//

.account-bg {
  background:$auth-bg;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 700;
    line-height: 150px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }
}

.auth-logo {
  .logo-dark {
      display: $display-block !important;
  }
  .logo-light {
      display: $display-none  !important;
  }
}


// authentication home icon

.account-page-full-height {
    height: 100vh;
  }
  
  @media (max-width: 1199px){
    .account-page-full-height {
      height: auto;
    }
    .account-process{
      &:before{
        display: none
      }
    }
  }
  
  
  .account-process{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 50px;
      left: 50%;
      height: 2px;
      width: 100%;
      border-top: 1px dashed $white;
    }
  }
