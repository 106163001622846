// form.scss

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
 &::placeholder{
   text-align: left /*rtl: right*/;
 }
}

.form-check{
  position: relative;
  text-align: left /*rtl: right*/;
}

.form-check-label{
    cursor: pointer;
    margin-bottom: 0;
}



