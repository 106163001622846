// 
// _horizontal.scss
// 

.topnav {
    background: $topnav-bg;
    padding: 0 calc(#{$grid-gutter-width} / 2);
    left: 0;
    right: 0;
    z-index: 1;
    
    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        
        .nav-link {
            font-size: 15px;
            position: relative;
            padding: 1rem 1.3rem;
            color: $sidebar-menu-item-color;
            i{
                font-size: 15px;
                display: inline-block;
            }
            &:focus, &:hover{
                color: $sidebar-menu-item-active-color;
                background-color: transparent;
            }
        }
        
        .dropdown-item{
            color: $sidebar-menu-item-color;
            &.active, &:hover{
                color: $sidebar-menu-item-active-color;
                background-color: transparent;
            }
        }
        
        .nav-item{
            .nav-link.active{
                color: rgba($white, 0.9);
            }
        }

        .dropdown{
            &.active{
              >a {
                    color: $sidebar-menu-item-active-color;
                    background-color: transparent;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    body[data-layout="horizontal"] {
        .container-fluid,
        .navbar-header {
            max-width: 90%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        .navbar-nav {
            .nav-item {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .dropdown-item {
            padding: .5rem 1.5rem;
            min-width: 180px;
            font-size: 15px;
        }

        .dropdown {
            .dropdown-menu {
                margin-top: 0;
                background-color: lighten($dropdown-bg, 2%);
                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        display: none
                    }
                }
            }

            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block
        }
    }

    .navbar-toggle {
        display: none;
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: .4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all .3s ease-out;
        width: .4em;
    }
}



@include media-breakpoint-down(lg) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    
    .topnav {
        max-height: 360px;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
            }
            .nav-item{
                .nav-link.active{
                    color: $sidebar-menu-item-active-color;
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}


body[data-layout="horizontal"] {
    #page-topbar{
        background: $bg-topbar-color;
        box-shadow: none;
        padding-bottom: 60px;
        position: inherit;
    }

    .navbar-header {
        box-shadow: none;
        position: relative;
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {
        position: relative;
        .form-control {
            background-color: rgba($white,0.07);
            color: rgba($white,0.5);
            border: none;
            padding-left: 20px;
            padding-right: 30px;
        }
        span {
            position: absolute;
            font-size: 12px;
            line-height: 38px;
            right: 13px;
            top: 0;
            color: $gray-600;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white,0.5);
        }
    }
    .header-item {
        color: $header-dark-item-color;
    
        &:hover {
            color: $header-dark-item-color;
        }
    }

    .navbar-header {
        .dropdown.show {
            .header-item{
                background-color: rgba($white,0.1);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    @include media-breakpoint-up(lg) {
        .topnav{
            background-color: $hori-nav-bg;
            .navbar-nav {
        
                .nav-link {
                    color: rgba($white, 0.5);
                    
                    &:focus, &:hover{
                        color: rgba($white, 0.9);
                    }
                }
        
                > .dropdown{
                    &.active{
                      >a {
                            color: rgba($white, 0.9) !important;
                        }
                    }
                }
            }
        }
    }
}

