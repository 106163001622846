// 
// Page-title
// 

.header-bg{
    background: $light fixed;
    padding-bottom: 60px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    margin: -40px -12px;
}

.page-title-box {
    padding: 20px 0px;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        font-size: 18px !important;
    }
}




body[data-layout="horizontal"] {
    .header-bg{
        background: $dash-gradient;

    }
    .page-title-box {
        padding: 0 calc(24px / 2);

        .page-title-content{
            padding: 22px 0;
        }
        .breadcrumb {
            background-color: transparent;
            padding: 0;
        }
    
        h4 {
            font-size: 24px !important;
            color: $white;
            margin-bottom: 0px;
        }
    }
}

